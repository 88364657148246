import { SET_SELECTEDTRACKID } from '../actions/index'

// Sélection d'une trace sur la carte
const selectedTrackIdReducer = (state = '', action) => {
    if (action.type === SET_SELECTEDTRACKID) {
        return action.payload
    }
    return state
}

export default selectedTrackIdReducer