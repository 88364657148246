
import { SET_STEPS } from '../actions/index'

// Mise à jour de la liste des étapes
const stepsReducer = (state = [], action) => {
    if (action.type === SET_STEPS) {
        return action.payload
    }
    return state
}

export default stepsReducer