
import { SET_CURRENTNOTE } from '../actions/index'

// Mise à jour de la note courante
const currentNoteReducer = (state = null, action) => {
    if (action.type === SET_CURRENTNOTE) {
        return action.payload
    }
    return state
}

export default currentNoteReducer