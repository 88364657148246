import { SET_VIEWSLIDER } from '../actions/index'

// Mise à jour de la liste des carnets de voyage
const viewSliderReducer = (state = true, action) => {
    if (action.type === SET_VIEWSLIDER) {
        return action.payload
    }
    return state
}

export default viewSliderReducer