import { SET_CURRENTTRACK } from '../actions/index'

import { COLOR_TRACK } from '../commons'

const track0 = {
    id: '',
    filename: '',
    color: COLOR_TRACK,
    visibility: true,
    file: null
}

// Mise à jour de l'étape courante
const currentTrackReducer = (state = { "current": track0, "previousId": ''}, action) => {
    if (action.type === SET_CURRENTTRACK) {
        return action.payload
    }
    return state
}

export default currentTrackReducer