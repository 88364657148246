
import { SET_TRACKS } from '../actions/index'

// Mise à jour de la liste des traces
const tracksReducer = (state = [], action) => {
    if (action.type === SET_TRACKS) {
        return action.payload
    }
    return state
}

export default tracksReducer