import { SET_NOTEINDEXES } from '../actions/index'

// Mise à jour de la liste des index de notes
const noteIndexesReducer = (state = [], action) => {
    if (action.type === SET_NOTEINDEXES) {
        return action.payload
    }
    return state
}

export default noteIndexesReducer