
import { SET_SELECTEDPOINT } from '../actions/index'

// Selection d'un point sur la carte
const selectedPointReducer = (state = '', action) => {
    if (action.type === SET_SELECTEDPOINT) {
        return action.payload
    }
    return state
}

export default selectedPointReducer