import { SET_MAPREADY } from '../actions/index'

// Mise à jour de l'étape courante
const mapReadyReducer = (state = false, action) => {
    if (action.type === SET_MAPREADY) {
        return action.payload
    }
    return state
}

export default mapReadyReducer