import { SET_TRAVELBOOKS } from '../actions/index'

// Mise à jour de la liste des carnets de voyage
const travelbooksReducer = (state = [], action) => {
    if (action.type === SET_TRAVELBOOKS) {
        return action.payload
    }
    return state
}

export default travelbooksReducer