
import { SET_CURRENTSTEP } from '../actions/index'

// Mise à jour de l'étape courante
const currentStepReducer = (state = null, action) => {
    if (action.type === SET_CURRENTSTEP) {
        return action.payload
    }
    return state
}

export default currentStepReducer