
import { SET_CURRENTTRAVELBOOK } from "../actions/index"

// Mise à jour de carnet de voyage courant
const currentTravelbookReducer = (state = null, action) => {
  if (action.type === SET_CURRENTTRAVELBOOK) {
    return action.payload
  }
  return state
}

export default currentTravelbookReducer